import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Revenue",
  "includeToc": true,
  "layout": "downloads",
  "description": "We offer revenue by month and year for January 2003 through the most recently completed month. The data includes federal offshore and onshore revenue, along with revenue from Native American lands. The data is further broken down by revenue type (e.g., bonuses, rents, and royalties) and commodity.",
  "tags": ["Data", "Downloads", "Documentation", "Federal", "Native American", "Indian", "Revenue", "Location", "By location", "Fiscal year", "Calendar year", "Monthly"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GlossaryTerm = makeShortcode("GlossaryTerm");
const DownloadDataFile = makeShortcode("DownloadDataFile");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/downloads/"
      }}>{`Downloads /`}</a></p>

    <h1 {...{
      "id": "Revenue",
      "style": {
        "position": "relative"
      }
    }}>{`Revenue`}</h1>
    <p>{`We offer revenue by month for January 2003 through the most recently completed month. The data includes revenue from federal lands, federal waters, and Native American lands. The data is further broken down by location, lease type, revenue type (e.g., bonuses, rents, and royalties), and commodity/product information.`}</p>
    <br />
We offer revenue data files for both calendar year and fiscal year. The calendar year data set is for years 2003-2023, and the fiscal year data set is for years 2004-2024. They are all <GlossaryTerm mdxType="GlossaryTerm">accounting year</GlossaryTerm> data. The data contains the same fields as the monthly download files.
    <br /><br />
    <p>{`Download data:`}</p>
    <DownloadDataFile linkType="DownloadCsv" dataSet="monthly_revenue.csv" mdxType="DownloadDataFile">
  Monthly revenue
    </DownloadDataFile>
    <DownloadDataFile linkType="DownloadCsv" dataSet="calendar_year_revenue.csv" mdxType="DownloadDataFile">
  Calendar year revenue
    </DownloadDataFile>
    <DownloadDataFile linkType="DownloadCsv" dataSet="fiscal_year_revenue.csv" mdxType="DownloadDataFile">
  Fiscal year revenue
    </DownloadDataFile>
    <DownloadDataFile linkType="DownloadXls" dataSet="all_revenue.xlsx" mdxType="DownloadDataFile">
Combined excel spreadsheet
    </DownloadDataFile>
    <h2 {...{
      "id": "Scope",
      "style": {
        "position": "relative"
      }
    }}>{`Scope`}</h2>
    <p>{`These datasets include natural resource revenues for U.S. federal lands, federal waters, and Native American lands. It does not include privately-owned lands or U.S. state lands. Federal revenue data is available by location. Native American data is only available at the national level to protect private and personally identifiable information.`}</p>
    <br />
The datasets include data tracked and managed by the Department of the Interior’s Office of Natural Resources Revenue (ONRR). The data is presented by month, from January 2003 through the most recently completed month.
    <h3 {...{
      "id": "Why-are-some-values-negative",
      "style": {
        "position": "relative"
      }
    }}>{`Why are some values negative?`}</h3>
    <p>{`Companies can adjust and correct their payments for up to seven years after a transaction takes place. If a company overpays their royalty, rent, or bonus, they are entitled to recoup their overpayment. If the overpayment and recoupment happen in different years, the recoupment will appear as a negative amount in ONRR's revenue summaries.`}</p>
    <h3 {...{
      "id": "Why-is-there-a-Gas-value-an-Oil-value-and-an-Oil--Gas-pre-production-value",
      "style": {
        "position": "relative"
      }
    }}>{`Why is there a Gas value, an Oil value, and an Oil & Gas (pre-production) value?`}</h3>
    <p>{`“Oil & Gas (pre-production)” is the commodity category used for offshore oil and gas rents and bonuses. At the time of lease sale, it isn’t known whether a lease will produce oil, gas or both oil and gas. After a lease starts producing a commodity (or commodities), the lease owner starts paying royalties. These can then be associated with either oil or gas. Hence, rent and bonus lines of data will be associated with an “Oil & Gas (pre-production)” commodity type, while royalty lines of data will be associated with either “Oil” or “Gas” commodity types.`}</p>
    <h3 {...{
      "id": "Why-are-federal-revenue-by-company-totals-different-from-calendar-year-totals-calculated-from-the-monthly-data",
      "style": {
        "position": "relative"
      }
    }}>{`Why are federal revenue by company totals different from calendar year totals calculated from the monthly data?`}</h3>
    <p>{`Our site has two federal revenue datasets. The one on this page is organized by location. `}<Link href='/downloads/federal-revenue-by-company/' linkType='default' mdxType="Link">{`The federal revenue by company dataset`}</Link>{` is organized by the company that paid the revenue. However, the national revenue totals are slightly different (by about $90 million). This is because the revenue by location dataset excludes revenue from offshore rights-of-way because they don't map to an offshore planning area.`}</p>
    <h3 {...{
      "id": "Note-Geothermal-rate-details",
      "style": {
        "position": "relative"
      }
    }}>{`Note: Geothermal rate details`}</h3>
    <p>{`The fees and rates for revenue from geothermal resources on federal land depend on whether the land is leased competitively or noncompetitively.`}</p>
    <br />
Also, different fee rates apply to pre-2005 leases and to direct use facilities:
    <ul>
      <li parentName="ul"><strong parentName="li">{`Leases signed before the Energy Policy Act of 2005.`}</strong>{` The lease holder’s reasonable actual transmission and generation costs are deducted from gross proceeds from electricity sales, and the resulting value is multiplied by the lease royalty rate (usually 10%).`}</li>
      <li parentName="ul"><strong parentName="li"><GlossaryTerm mdxType="GlossaryTerm">{`Direct use`}</GlossaryTerm>{`.`}</strong>{` The lease holder pays the equivalent value of the least expensive, reasonable alternative energy source. Thermal energy utilized must be measured by lease holder at the inlet and outlet of facility. The resulting value is multiplied by the lease royalty rate of 10%.`}</li>
    </ul>
    <p>{`The Bureau of Land Management has `}<a parentName="p" {...{
        "href": "https://www.blm.gov/programs/energy-and-minerals/renewable-energy/geothermal-energy"
      }}>{`more information about geothermal energy on federal lands `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{`.`}</p>
    <h2 {...{
      "id": "Data-dictionary",
      "style": {
        "position": "relative"
      }
    }}>{`Data dictionary`}</h2>
    <h3 {...{
      "id": "Fields-and-definitions",
      "style": {
        "position": "relative"
      }
    }}>{`Fields and definitions`}</h3>
    <p><strong parentName="p">{`Date`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Date:`}</strong>{`  The date the revenue was collected by ONRR.`}</li>
      <li parentName="ul"><strong parentName="li">{`Calendar Year:`}</strong>{`  The period between January 1 and December 31 for a given year.`}</li>
      <li parentName="ul"><strong parentName="li">{`Fiscal Year:`}</strong>{`  The year the revenue was generated. The federal fiscal year runs from October 1 of the prior year through September 30 of the year being described. For example, Fiscal Year 2018 is between October 1, 2017,  and September 30, 2018.`}</li>
    </ul>
    <p><strong parentName="p">{`Land Class:`}</strong>{` This field distinguishes federal lands and waters from Native American lands.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Federal:`}</strong>{`  `}<a parentName="li" {...{
          "href": "https://sgp.fas.org/crs/misc/R42346.pdf"
        }}>{`Federal lands (PDF) `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` are owned by or under the jurisdiction of the federal government. Federal lands include: public domain lands, acquired lands, military acquired lands, and the `}<a parentName="li" {...{
          "href": "https://www.boem.gov/oil-gas-energy/leasing/outer-continental-shelf"
        }}>{`Outer Continental Shelf `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Native American:`}</strong>{`  Includes Tribal lands held in trust by the federal government for a tribe’s use, and allotments held in trust by the federal government for individual Native American use.`}</li>
    </ul>
    <p><strong parentName="p">{`Land Category:`}</strong>{` This field distinguishes between onshore and offshore revenue.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Onshore:`}</strong>{`  Situated or occurring on land.`}</li>
      <li parentName="ul"><strong parentName="li">{`Offshore:`}</strong>{`  Submerged lands located farther than three miles off a state’s coastline, or three marine leagues into the Gulf of Mexico off Texas and western Florida`}</li>
      <li parentName="ul"><strong parentName="li">{`Not Tied to a Lease:`}</strong>{`  Some revenues - such as civil penalties - are not tied to either onshore or offshore.`}</li>
    </ul>
    <p><strong parentName="p">{`State:`}</strong>{`  Contains the name of the state.`}</p>
    <br />
    <p><strong parentName="p">{`County:`}</strong>{`  Contains county, parish, and borough names.`}</p>
    <br />
    <p><strong parentName="p">{`FIPS Code:`}</strong>{`  Federal Information Processing Standards (FIPS), now known as Federal Information Processing Series, are numeric codes assigned by the National Institute of Standards and Technology (NIST). Typically, FIPS codes deal with U.S. states and counties. U.S. states are identified by a 2-digit number, while US counties are identified by a 3-digit number.`}</p>
    <br />
    <p><strong parentName="p">{`Offshore Region:`}</strong>{` BOEM separates offshore areas into four regions: Gulf of Mexico, Atlantic, Pacific, and Alaska. For more information on offshore regions, including spatial boundaries, see BOEM's `}<a parentName="p" {...{
        "href": "https://www.boem.gov/oil-gas-energy/mapping-and-data"
      }}>{`maps and GIS data `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{`.`}</p>
    <br />
    <p><strong parentName="p">{`Revenue Type:`}</strong>{`  Revenues from U.S. natural resources fall into one of several types.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Royalties:`}</strong>{`  A natural resource lease holder pays royalties after the lease starts producing a commodity in paying quantities. The amount is based on a percentage of the revenue from the commodity sold. The exact percentage is set in the original lease document that went along with the lease sale.`}</li>
      <li parentName="ul"><strong parentName="li">{`Bonus:`}</strong>{`  The amount paid by the highest successful bidder for a natural resource lease, or the winning bid.`}</li>
      <li parentName="ul"><strong parentName="li">{`Rents:`}</strong>{`  A natural resource lease might not produce anything in paying quantities for some time after it is sold. Until it does, periodic payments are made for the right to continue exploration and development of the land for future natural resource production. These payments are called rent.`}</li>
      <li parentName="ul"><strong parentName="li">{`Civil Penalties:`}</strong>{`  ONRR issues civil penalties when companies fail to comply with, or knowingly or willfully violate, regulations or laws.`}</li>
      <li parentName="ul"><strong parentName="li">{`Inspection Fees:`}</strong>{`  The Department of the Interior inspects offshore oil and gas drilling rigs at least once a year. Inspection fees help recover some of the costs associated with these inspections.`}</li>
      <li parentName="ul"><strong parentName="li">{`Other Revenues:`}</strong>{`  This category includes revenues that are not included in the royalty, rent, or bonus categories. Other revenues contain minimum royalties, estimated royalties, settlement agreements, and interest.`}</li>
    </ul>
    <p><strong parentName="p">{`Mineral Lease Type:`}</strong>{`  The type of lease revenue is being generated from. An oil or gas mineral lease type can generate revenue not only from oil or gas but also, CO2, helium, NGL, and sulfur.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Asphalt`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Chat`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Cinders`}</strong></li>
      <li parentName="ul"><strong parentName="li">{` Clay`}</strong></li>
      <li parentName="ul"><strong parentName="li">{` Coal`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Copper`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Garnet`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gemstones`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gilsonite`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gold`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gypsum`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Hardrock`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Hot Springs`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Limestone`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Mining - Unspecified`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Oil & Gas`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Oil Shale`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Phosphate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Potassium`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Quartz`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sand & Gravel`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Silica Sand`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sodium`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sulfur`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Tar Sands`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Wind`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Commodity:`}</strong>{` The Department of the Interior collects revenues on over 60 different products. The majority of revenues come from oil, gas, coal, and renewables (geothermal and wind), but you will find many other product categories in these datasets.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Asbestos`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Asphalt`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Chat`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Cinders`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Clay`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`CO2`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Coal`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Cobalt`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Copper`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Garnet`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gas`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gemstones`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gilsonite`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gold`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gypsum`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Hardrock`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Helium`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Hot Springs`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Humate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Leonardite`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Limestone`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Mining Water`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Mining - Unspecified`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Molybdenum Concentrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`NGL`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Oil`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Oil & Gas (pre-production)`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Oil Shale`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Phosphate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Potassium`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Quartz`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sand & Gravel`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Silica Sand`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Silver`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Soda Ash`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sodium`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sulfur`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Tar Sands`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Wavellite`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Wind`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Zinc`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Product:`}</strong>{` Commodity type is often further broken down into product.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Anhydrous Sodium Sulfate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Asbestos`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Asphaltic Crude`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Black Wax Crude`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Borax-Anhydrous`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Borax-Decahydrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Borax-Pentahydrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Boric Acid`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Borrow Sand & Gravel`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Brine Barrels`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Calcium Chloride`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Carbon Dioxide`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Carbon Dioxide Gas (CO2)`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Caustic`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Cinders`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Clay`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Coal`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Coal Bed Methane`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Coal Waste (Sub-Econ)`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Coal-Bituminous-Processed`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Coal-Bituminous-Raw`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Coal-Fines Circuit`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Coal-Lignite-Raw`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Coal-Subbituminous-Processed`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Coal-Subbituminous-Raw`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Cobalt Concentrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Condensate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Copper`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Copper Concentrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Drip or Scrubber Condensate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Ferro Phosphorous Slag`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Flash Gas`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Fuel Oil`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gas Hydrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gas Lost - Flared or Vented`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gas Plant Products`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Commercially Demineralized H2O`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Direct Use, Millions of Gallons`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Direct Utilization, Hundreds of Gallons`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Direct Utilization, Millions of BTUs`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Direct Utilization, Other`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Electrical Generation, Kilowatt Hours`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Electrical Generation, Other`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Electrical Generation, Thousands of Pounds`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Sulfur, long tons`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gilsonite`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gold`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gold Ore`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gold Placer`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Granulated Langbeinite`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Gypsum`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Helium`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Humate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Inlet Scrubber`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Langbeinite`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Langbeinite-Coarse`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Langbeinite-Granular`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Langbeinite-Special Std`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Langbeinite-Standard`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Lead Concentrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Leonardite`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Limestone`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Magnesium Chloride Brine`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Manure Salts`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Mine Water`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Molybdenum Concentrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Muriate Of Potash-Coarse`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Muriate Of Potash-Granular`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Muriate Of Potash-Standard`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Nitrogen`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Oil`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Oil Lost`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Other Liquid Hydrocarbons`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Phosphate Concentrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Phosphate Raw Ore`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Pipeline fuel/loss`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Potash`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Potassium Sulphate Special Std`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Potassium Sulphate-Granular`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Potassium Sulphate-Standard`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Processed (Residue) Gas`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Purge Liquor`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Quartz Crystal`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Salt`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Salt-Waste`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sand/Gravel`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sand/Gravel-Cubic Yards`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Silver`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Soda Ash`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Soda Ash-Granular`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sodium Bi-Carbonate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sodium Bisulfite`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sodium Decahydrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sodium Sesquicarbonate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sour Crude`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sulfide`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sulfur`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sweet Crude`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Sylvite-Raw Ore`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Trona Ore`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Unprocessed (Wet) Gas`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Wavellite`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Yellow Wax Concentrate`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Zinc Concentrate`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Revenue:`}</strong>{` Total revenue.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      